import { useStaticQuery, graphql } from 'gatsby'
import { useThemeUI } from 'theme-ui'
import { Section } from '@monobits/components'
import { Locomotion } from '@monobits/locomotive-scroll'

import Image, { getImage } from './Image'

const parallaxHeight = (speed, buffer = 5) => {
  const offset = Math.sign(speed) === 1 ? buffer : 1

  return 100 + Math.abs(speed) * (buffer * offset) + '%'
}

const ImageBanner = ({ name, placeholder, alt, loading = 'lazy', vh, speed, sx, ...props }) => {
  const data = useStaticQuery(graphql`
    query ImagesQuery {
      allFile {
        images: edges {
          node {
            name
            base
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  `)

  const image = getImage(data.allFile.images, name)
  const { banner } = useThemeUI().theme

  const value = {
    speed: speed || banner?.speed,
    height: vh || banner?.height,
  }

  const BackgroundImage = (
    <Image
      image={image}
      loading={loading}
      alt={alt}
      sx={{
        width: '100%',
        height: '100%',
        maxWidth: 'none',
      }}
    />
  )

  return (
    <Section
      variant="strict"
      sx={{
        variant: 'banner',
        bg: image?.backgroundColor,
        ...sx,
      }}
      vh={value.height}
      {...props}
    >
      {!value.speed ? (
        BackgroundImage
      ) : (
        <Locomotion
          speed={value.speed}
          sx={{ minHeight: parallaxHeight(value.speed), height: 'inherit', width: 'auto' }}
          wrapped
        >
          {BackgroundImage}
        </Locomotion>
      )}
    </Section>
  )
}

export default ImageBanner
