import { Button as MonoButton } from '@monobits/components'
import { setVariantString } from '@monobits/theme-core'
import Icons from './Icons'

const Button = ({ children, icon, layout, ...props }) => {
  const alignment = icon ? 'withicon' : layout

  return (
    <MonoButton sx={{ '&': { variant: setVariantString('button.layout', alignment) } }} {...props}>
      {children}
      {icon && <Icons name={icon} />}
    </MonoButton>
  )
}

export default Button
