import { GatsbyImage, getImage as getImagePlugin } from 'gatsby-plugin-image'

const filterNodes = (data, name) => {
  const selected = data?.filter(({ node }) => node.name === name)[0].node
  return getImagePlugin(selected)
}

export const getImage = (data, name) => {
  if (name) return filterNodes(data, name)
  return (i) => filterNodes(data, i)
}

const Image = ({ image, placeholder = 'dominantColor', ...props }) => {
  const options = {
    backgroundColor: placeholder === 'dominantColor' && image?.backgroundColor,
    ...props,
  }

  return <GatsbyImage image={image} sx={{ pointerEvents: 'none' }} {...options} />
}

export default Image
