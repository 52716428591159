import { Text } from '@monobits/components'

import { useSetRecoilState } from 'recoil'
import { wholesaleState } from '../atoms'
import { ProductsContext } from './ProductsList'

import ScrollTo from './ScrollTo'
import Button from './Button'
import ButtonGroup from './ButtonGroup'
import TextIndent from './TextIndent'
import ListItem from './List/ListItem'

const Product = ({ product: { brochure, wholesale, content, ...data }, ...props }) => {
  const setFormOptions = useSetRecoilState(wholesaleState)

  const [brochureText, pdf] = brochure ?? {}
  const [wholesaleText, anchor] = wholesale ?? {}

  return (
    <ListItem context={ProductsContext} {...data} {...props}>
      <Text variant="caption">
        <TextIndent>{content}</TextIndent>
      </Text>

      <ButtonGroup>
        {wholesale != null && (
          <Button
            as={ScrollTo}
            anchor={anchor}
            onClick={() =>
              setFormOptions({
                option: 'Wholesale',
                product: data.title,
              })
            }
            icon="arrow-right-circle"
            variant="stroked"
          >
            {wholesaleText}
          </Button>
        )}
        {brochure != null && (
          <Button as="a" href={pdf} icon="arrow-down-circle">
            {brochureText}
          </Button>
        )}
      </ButtonGroup>
    </ListItem>
  )
}

export default Product
