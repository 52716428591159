import { graphql } from 'gatsby'
import { GatsbySeo } from 'gatsby-plugin-next-seo'

import Head from '../components/Head'
import Anchor from '../components/Anchor'
import ContentGroup from '../components/ContentGroup'
import ContentBlock from '../components/ContentBlock'
import ImageBanner from '../components/ImageBanner'
import InvertedTheme from '../components/Inverted'
import ProductsList from '../components/ProductsList'
import InquiryForm from '../components/InquiryForm'
import Footer from '../components/Footer'

const IndexPage = ({ data }) => {
  const {
    blocks: { intro, solutions, about, custom, specialists, footer },
  } = data.site.meta ?? {}

  return (
    <>
      <GatsbySeo title="Welcome" />

      <Anchor.Source handles={['welcome', 'solutions', 'products', 'capabilities', 'inquiry']} />

      <Anchor index={0}>
        <Head {...intro} />
      </Anchor>
      <ImageBanner name="amsam-header" loading="eager" alt="Header Image" />

      <Anchor index={1} as={ContentGroup}>
        <ContentBlock {...solutions} />
      </Anchor>
      <ImageBanner name="amsam-liquid" alt="Liquids" />

      <InvertedTheme>
        <Anchor index={2} as={ContentGroup}>
          <ContentBlock {...about} />
          <ProductsList />
        </Anchor>
      </InvertedTheme>
      <ImageBanner name="amsam-bottles" alt="Bottles" />

      <Anchor index={3} as={ContentGroup} sx={{ overflow: 'hidden' }}>
        <ContentBlock {...custom} />
      </Anchor>

      <InvertedTheme>
        <Anchor index={4} as={ContentGroup}>
          <ContentBlock {...specialists}>
            <InquiryForm />
          </ContentBlock>
        </Anchor>
      </InvertedTheme>
      <ImageBanner name="amsam-footer" alt="Footer Image" />

      <ContentGroup>
        <Footer {...footer} />
      </ContentGroup>
    </>
  )
}

export const query = graphql`
  query HomePageQuery {
    site {
      meta: siteMetadata {
        blocks {
          intro {
            heading
            contact
          }
          solutions {
            heading
            caption
            text
            brochure
          }
          about {
            heading
            caption
            text
          }
          custom {
            marquee
            caption
            text
            contact
          }
          specialists {
            heading
            caption
            form
          }
          footer {
            heading
            form
            brochure
            contact
          }
        }
      }
    }
  }
`

export default IndexPage
