import { ThemeProvider, useThemeUI } from 'theme-ui'

const invertedTheme = (colors) => ({
  colors: colors.modes?.light,
})

const Inverted = ({ children }) => {
  const context = useThemeUI().theme

  return <ThemeProvider theme={invertedTheme(context?.rawColors)}>{children}</ThemeProvider>
}

export default Inverted
