import { useEffect } from 'react'

const HiddenField = ({ handle, value, handler = () => {} }) => {
  useEffect(() => {
    handler({ target: { name: handle, value } })
  }, [value, handle])

  return (
    <input
      id={handle}
      name={handle}
      type="text"
      aria-hidden="true"
      sx={{ display: 'none!important' }}
      value=""
      readOnly
    />
  )
}

export default HiddenField
