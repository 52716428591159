import { Icon } from '@monobits/components'

import ArrowDown from '../../assets/svg/arrow-down.svg'
import ArrowDownCircle from '../../assets/svg/arrow-down-circle.svg'
import ArrowRightCircle from '../../assets/svg/arrow-right-circle.svg'
import Cross from '../../assets/svg/cross.svg'
import Facebook from '../../assets/svg/facebook.svg'
import Instagram from '../../assets/svg/instagram.svg'
import LinkedIn from '../../assets/svg/linkedin.svg'
import Mail from '../../assets/svg/mail.svg'
import Phone from '../../assets/svg/phone.svg'

const icons = {
  'arrow-down': ArrowDown,
  'arrow-down-circle': ArrowDownCircle,
  'arrow-right-circle': ArrowRightCircle,
  cross: Cross,
  facebook: Facebook,
  instagram: Instagram,
  linkedin: LinkedIn,
  mail: Mail,
  phone: Phone,
}

const Icons = ({ name, ...props }) => <Icon icon={name in icons && icons[name]} {...props} />

export default Icons
