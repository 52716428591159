import { Grid } from 'theme-ui'
import cls from 'classnames'

const ButtonGroup = ({ children, as = 'aside', sx, className, fit, semi, ...props }) => (
  <Grid sx={{ variant: 'button.group', ...sx }} className={cls(className, { '-fit': fit, '-semi': semi })} {...props}>
    {children}
  </Grid>
)

export default ButtonGroup
