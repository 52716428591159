import { useStaticQuery, graphql } from 'gatsby'
import { useRecoilValue } from 'recoil'
import { NetlifyForm, Honeypot } from 'react-netlify-forms'
import { Heading } from '@monobits/components'
import { Box } from 'theme-ui'
import Button from './Button'
import ButtonGroup from './ButtonGroup'
import Field from './Forms/Field'
import Select from './Forms/Select'
import HiddenField from './Forms/HiddenField'

import { wholesaleState } from '../atoms'

const Form = ({ sx, ...props }) => {
  const data = useStaticQuery(graphql`
    query InqueryQuery {
      site {
        meta: siteMetadata {
          locales {
            field: forms {
              firstName
              lastName
              email
              phone
              company
              message
              submit
              inquiry {
                title
                entries
              }
              success
              error
            }
          }
        }
      }
    }
  `)

  const wholesale = useRecoilValue(wholesaleState)
  const { field } = data.site.meta.locales ?? {}

  return (
    <NetlifyForm name="Inquiries" action="/thanks" honeypotName="bot-field">
      {({ handleChange: watch, success, error }) => (
        <Box
          sx={{ variant: 'forms', ...sx }}
          data-form-state={(success && 'success') || (error && 'error') || null}
          {...props}
        >
          <Honeypot />

          <article className="overlay">
            {(success || error) && <Heading variant="caption">{(success && field.success) || field.error}</Heading>}
          </article>

          <ButtonGroup fit>
            <Field name={field.firstName} type="text" handler={watch} required />
            <Field name={field.lastName} type="text" handler={watch} required />
          </ButtonGroup>

          <Select
            handle="inquiry"
            name={field.inquiry.title}
            options={field.inquiry.entries}
            defaultValue={wholesale.option}
            handler={watch}
            required
          />

          <HiddenField handle="product" value={wholesale.product} handler={watch} />

          <Field name={field.email} handle="email" type="email" handler={watch} required />
          <Field name={field.phone} handle="phone" type="tel" pattern="[- +()0-9]+" handler={watch} required />
          <Field name={field.company} handle="company" type="text" handler={watch} />
          <Field name={field.message} handle="message" type="textarea" handler={watch} />

          <Button layout="left" sx={{ width: '100%' }} type="submit">
            {field.submit}
          </Button>
        </Box>
      )}
    </NetlifyForm>
  )
}

export default Form
