import { useStaticQuery, graphql } from 'gatsby'
import { NetlifyForm, Honeypot } from 'react-netlify-forms'
import { Box } from 'theme-ui'
import { Text } from '@monobits/components'

import Field from './Forms/Field'

const MailingListForm = ({ sx, ...props }) => {
  const data = useStaticQuery(graphql`
    query MailingQuery {
      site {
        meta: siteMetadata {
          locales {
            field: forms {
              email
            }
            newsletter {
              message
              success
              error
            }
          }
        }
      }
    }
  `)

  const { field, newsletter: text } = data.site.meta.locales ?? {}

  return (
    <NetlifyForm name="Mailing List" action="/thanks" honeypotName="bot-field">
      {({ handleChange: watch, success, error }) => (
        <Box
          sx={{
            variant: 'forms',
            gridGap: 1,
            ...sx,
          }}
          {...props}
        >
          <Honeypot />
          <Text as="h4">{(success && text.success) || (error && text.error) || text.message}</Text>
          {!success && <Field name={field.email} handle="email" type="email" handler={watch} autosubmit required />}
        </Box>
      )}
    </NetlifyForm>
  )
}

export default MailingListForm
