import { Grid } from 'theme-ui'
import { Section, Container, Heading, Paragraphs, Marquee } from '@monobits/components'
import cls from 'classnames'

import ScrollTo from './ScrollTo'
import Button from './Button'
import ButtonGroup from './ButtonGroup'
import TextIndent from './TextIndent'

const Caption = ({ text, className, ...props }) =>
  text != null && (
    <Heading as="h3" variant="caption" className={cls('-caption', className)} {...props}>
      {text}
    </Heading>
  )

const ContentBlock = ({ children, sx, ...props }) => {
  const { heading, caption, text, marquee, brochure, contact, ...rest } = props
  const buttons = brochure || contact
  const content = caption || text || buttons || children

  const [brochureText, pdf] = brochure ?? {}
  const [contactText, anchor] = contact ?? {}

  return (
    <Section {...rest}>
      <Container sx={{ variant: 'block' }}>
        <Caption text={caption} className="-hide-desktop" />

        {heading && (
          <Heading variant="title">
            <TextIndent>{heading}</TextIndent>
          </Heading>
        )}

        {marquee && (
          <Marquee speed="35s">
            <Heading sx={{ whiteSpace: 'nowrap', mr: '1em' }} variant="emphasized">
              {marquee}
            </Heading>
          </Marquee>
        )}

        {content && (
          <Grid as="section" sx={{ variant: 'block.content' }}>
            <Caption text={caption} className="-hide-mobile -hide-tablet" />

            <Grid className="-content">
              {text && <Paragraphs sx={{ maxWidth: 800 }} data={text} rte={false} />}

              {children}

              {buttons && (
                <ButtonGroup semi>
                  {brochure != null && (
                    <Button as="a" href={pdf} icon="arrow-down-circle">
                      {brochureText}
                    </Button>
                  )}
                  {contact != null && (
                    <Button as={ScrollTo} anchor={anchor}>
                      {contactText}
                    </Button>
                  )}
                </ButtonGroup>
              )}
            </Grid>
          </Grid>
        )}
      </Container>
    </Section>
  )
}

export default ContentBlock
