import { createContext, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Section } from '@monobits/components'

import Product from './Product'

export const ProductsContext = createContext()

const ProductsList = ({ ...props }) => {
  const data = useStaticQuery(graphql`
    query ProductsQuery {
      site {
        meta: siteMetadata {
          products {
            title
            caption
            content
            brochure
            wholesale
          }
        }
      }
    }
  `)

  const { products } = data.site.meta ?? {}
  const [active, setActive] = useState('')
  const [over, setOver] = useState(false)

  return (
    <ProductsContext.Provider value={{ active, setActive }}>
      <Section {...props} onPointerEnter={() => setOver(true)} onPointerLeave={() => setOver(false)} data-fade={over}>
        {products.map((product, i) => (
          <Product key={product.title + i} product={product} />
        ))}
      </Section>
    </ProductsContext.Provider>
  )
}

export default ProductsList
