import { useContext } from 'react'
import { useThemeUI } from 'theme-ui'
import { Collapsible as Base, Heading } from '@monobits/components'
import { withHover } from '@monobits/core'
import { kebabCase } from 'lodash'

import Icons from '../Icons'

const Caption = ({ text, ...props }) =>
  text != null && (
    <Heading variant="caption" {...props}>
      + {text}
    </Heading>
  )

const Collapsible = ({ children, title, caption, className, hover, context, ...props }) => {
  const {
    transition: { duration },
    bezier,
  } = useThemeUI().theme?.motion.collapsible ?? {}

  const { active, setActive } = useContext(context)

  const handle = kebabCase(title)
  const opened = !!(handle === active)

  return (
    <div
      sx={{
        variant: 'list',
        '--speed': `${duration}s`,
        '--ease': bezier,
      }}
      data-selected={!!hover || opened}
      aria-expanded={opened}
      {...props}
    >
      <button sx={{ variant: 'list.head' }} onClick={() => setActive((prev) => (prev === handle ? '' : handle))}>
        <Heading variant="caption">{title}</Heading>
        <Caption text={caption} className="-italic -hide-mobile-block -hide-tablet-block" />
        <aside sx={{ variant: 'list.icon' }}>
          <Icons name="cross" />
        </aside>
      </button>
      <Base onTrigger={!opened} sx={{ variant: 'list.body' }}>
        <Caption text={caption} className="-italic -hide-desktop-block" />
        {children}
      </Base>
    </div>
  )
}

export default withHover(Collapsible, { mouse: true })
