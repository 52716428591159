import { Label, Input, Textarea } from 'theme-ui'
import { snakeCase } from 'lodash'
import { useDebouncedState } from '@react-hookz/web'

import Icons from '../Icons'

const types = {
  textarea: Textarea,
}

const Field = ({ name, type, handle, autosubmit, sx, onChange = () => {}, handler = () => {}, ...props }) => {
  const id = handle || snakeCase(name)
  const [change, setChange] = useDebouncedState('', 300, 500)

  const Component = types[type] || Input

  return (
    <div
      data-value={!!change}
      sx={{
        position: 'relative',
        variant: autosubmit && 'forms.autosubmit',
        ...sx,
      }}
    >
      <Component
        id={id}
        name={id}
        type={type}
        onChange={(event) => {
          setChange(event.target.value)
          handler(event)
          onChange()
        }}
        {...props}
      />
      <Label htmlFor={id}>{name}</Label>
      {autosubmit && (
        <button className="icon" type="submit">
          <Icons name="arrow-right-circle" />
        </button>
      )}
    </div>
  )
}

export default Field
