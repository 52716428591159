import { Section, Container, Heading } from '@monobits/components'
import { useLocomotiveScroll } from '@monobits/locomotive-scroll'
import { useRefs, useGSAPEffect } from '@monobits/gsap'

import ScrollTo from './ScrollTo'
import Button from './Button'

const Head = ({ heading, contact, children, sx, ...props }) => {
  const { scroll: locomotive } = useLocomotiveScroll()

  let refs = useRefs(['head', 'content', 'overlay', 'line'])

  useGSAPEffect(
    ({ gsap, $ }) => {
      const { head, content, overlay, line } = refs

      const getProperty = (ref, prop = 'height') => gsap.getProperty($(ref), prop)

      return gsap
        .timeline({ paused: true, defaults: { ease: 'power1.inOut' } })
        .set($(line), { willChange: 'transform' })
        .set($(content), { y: 50 })
        .to($(line), { scaleY: 1, duration: 1, ease: 'line' })
        .add('loaded')
        .to($(overlay), { scaleY: getProperty(head) / getProperty(overlay), duration: 1, ease: 'scale' }, 'loaded')
        .to($(line), { transformOrigin: 'top', scaleY: 0, duration: 0.85, ease: 'line' }, 'loaded+=0.35')
        .to($(content), { opacity: 1, y: 0, duration: 0.75, ease: 'power1.out' }, '<20%')
        .to('header', { y: 0, opacity: 1, duration: 1.25, ease: 'power1.out' }, '<20%')
    },
    ({ timeline }) => {
      timeline.play()
    },
    [locomotive],
    ({ count }) => locomotive != null && count === 0
  )

  const [contactText, anchor] = contact ?? {}

  return (
    <Section variant="hero" ref={refs.head} sx={{ variant: 'layout.head', ...sx }} {...props}>
      <Container variant="box" ref={refs.content} sx={{ variant: 'layout.head.grid', opacity: 0, zIndex: '3' }}>
        <Heading as="h1" variant="title" sx={{ maxWidth: 1600 }} cropped={false}>
          {heading}
        </Heading>

        {children}

        {contact != null && (
          <Button as={ScrollTo} anchor={anchor}>
            {contactText}
          </Button>
        )}
      </Container>

      <section ref={refs.overlay} sx={{ variant: 'intro', ...sx }} {...props}>
        <div ref={refs.line} className="-line" />
      </section>
    </Section>
  )
}

export default Head
