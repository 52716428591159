import { useEffect } from 'react'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { Box } from 'theme-ui'
import { pageAnchors } from '../atoms'

const Anchor = ({ children, index, ...props }) => {
  const source = useRecoilValue(pageAnchors)
  const handle = source[index]

  return (
    <Box
      data-scroll
      data-scroll-repeat
      data-scroll-offset={'50%,50%'}
      data-scroll-call={handle}
      data-handle={handle}
      {...props}
    >
      {children}
    </Box>
  )
}

const Source = ({ handles = [] }) => {
  const setAnchors = useSetRecoilState(pageAnchors)
  useEffect(() => setAnchors(handles), [handles])
  return null
}

Anchor.Source = Source

export default Anchor
