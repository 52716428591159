import { Grid } from 'theme-ui'
import { Section, Heading } from '@monobits/components'

import MailingListForm from './MailingListForm'
import TextIndent from './TextIndent'
import Button from './Button'
import ButtonGroup from './ButtonGroup'
import ScrollTo from './ScrollTo'

const Footer = ({ heading, brochure, contact }) => {
  const [brochureText, pdf] = brochure ?? {}
  const [contactText, anchor] = contact ?? {}

  return (
    <Section as="footer" variant="vh" sx={{ variant: 'layout.footer' }}>
      <Heading variant="title" cropped={false}>
        <TextIndent>{heading}</TextIndent>
      </Heading>
      <Grid sx={{ variant: 'layout.footer.content' }}>
        <MailingListForm className="form" />
        <ButtonGroup className="buttons">
          {brochure != null && (
            <Button as="a" href={pdf} icon="arrow-down-circle">
              {brochureText}
            </Button>
          )}
          {contact != null && (
            <Button as={ScrollTo} anchor={anchor} icon="mail">
              {contactText}
            </Button>
          )}
        </ButtonGroup>
      </Grid>
    </Section>
  )
}

export default Footer
