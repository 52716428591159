import { useRef, useEffect, useState } from 'react'
import { useClickOutside } from '@react-hookz/web'
import { Label } from 'theme-ui'
import { snakeCase } from 'lodash'

import Icons from '../Icons'

const Select = ({
  name,
  options,
  handle,
  defaultValue = null,
  sx,
  onChange = () => {},
  handler = () => {},
  ...props
}) => {
  const ref = useRef(null)
  const id = handle || snakeCase(name)

  const [open, setOpen] = useState(null)
  const [selected, setSelected] = useState(defaultValue)

  const onOptionSelect = (value, native) => {
    if (!native) setOpen(false)
    setSelected(value)
    onChange()
  }

  // Hack for Netlify
  useEffect(() => {
    handler({
      target: {
        name: id,
        value: selected,
      },
    })
  }, [selected, id])

  useEffect(() => setSelected(defaultValue), [defaultValue])

  useClickOutside(ref, () => setOpen(false))

  return (
    <div sx={{ variant: 'forms.select', ...sx }} aria-expanded={open} data-value={!!selected || false}>
      <aside onClick={() => setOpen((prev) => !prev)}>
        <select
          id={id}
          name={id}
          value={selected || ''}
          onChange={(event) => onOptionSelect(options[event.target.options.selectedIndex - 1], true)}
          {...props}
        >
          <option hidden disabled value="" />
          {options.map((option, i) => (
            <option key={option + i} value={option} children={option} />
          ))}
        </select>
        <Icons name="arrow-down" />
      </aside>

      <Label htmlFor={id} children={name} />

      <ul ref={ref} sx={{ variant: 'forms.select.dropdown' }} aria-hidden={!open}>
        {options.map((option, i) => (
          <li
            key={'custom' + option + i}
            data-selected={!!(option === selected) ? true : null}
            onClick={() => onOptionSelect(option, false)}
          >
            {option}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Select
